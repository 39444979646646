import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

const defaultLocale = 'en_US';

i18n
  .use(initReactI18next)
  .init({
    resources: {}, /// resources will be loaded later based on URL
    lng: defaultLocale,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
