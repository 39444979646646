import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { shallow } from 'zustand/shallow';
import { useResearchGroups } from '../api/useResearchGroups';
import { useFilterResearchGroups } from '../hooks/useFilterResearchGroups';
import { useFilterStore } from '../../../stores/filterStore';
import { ResearchGroupCard } from './ResearchGroupCard';
import { Button } from '../../../components/Elements/Button';
import { Card, CardTitle } from '../../../components/Elements/Card';
import { useGroupResearchGroups } from '../hooks/useGroupResearchGroups';

export const Country = () => {
  const { data } = useResearchGroups();
  const { country = 'default_country' } = useParams();
  const { researchTypes, resources } = useFilterStore((state) => ({
    researchTypes: state.researchTypes,
    resources: state.resources,
  }), shallow);
  const { filteredResearchGroups } = useFilterResearchGroups(
    data || [],
    researchTypes || [''],
    resources || [''],
    country || '',
  );
  const { groupedResearchGroups } = useGroupResearchGroups(filteredResearchGroups || []);
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const {
    researchGroupsCount, phdStudentCount, postdocResearcherCount, researcherCount, biobankCount,
    bioinformaticsDatabasesCount, clinicalTrialsCount, databasesCount, pcCellLinesCount,
    pcMouseModelsCount, populationBasedStudiesCount, registriesCount,
  } = groupedResearchGroups;

  const pathD = 'm19.996281,3.172512c-9.642361,0 -17.458275,7.818017 -17.458275,17.46199c0,9.647755 '
  + '17.458275,42.192986 17.458275,42.192986s17.465714,-32.545231 17.465714,-42.192986'
  + 'c0,-9.643973 -7.815916,-17.46199 -17.465714,-17.46199z';

  const pin = {
    __html: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="64" viewBox="0 0 40 64">
    <!-- Main shape with border -->
    <path d="${pathD}" fill="#C70A86" stroke="white" stroke-width="2"/>
    <text 
        x="50%" 
        y="35%" 
        dominant-baseline="middle" 
        text-anchor="middle" 
        fill="white" 
        font-size="28px" 
        font-family="Arial">
        ${filteredResearchGroups.length}
    </text>
</svg>`,
  };

  const svgFilename = `${country.replace(/ /g, '-').toLowerCase()}.svg`;

  const imageStyles = {
    fill: '#D3C1DB',
    maxHeight: '180px',
    height: '100%',
    margin: '0 auto',
    filter: 'invert(0.3) sepia(1) saturate(5) hue-rotate(274deg) contrast(1.4)',
  };

  return (
    <div className="">
      <Card>
        <div className="w-1/3">
          <div className="p-4" style={{ position: 'relative', maxHeight: '200px' }}>
            <img
              alt={country}
              src={`${process.env.PUBLIC_URL}/svg/${svgFilename}`}
              style={imageStyles}
            />
            <div
              dangerouslySetInnerHTML={pin}
              style={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        </div>
        <div className="w-2/3 flex-col">
          <CardTitle className="justify-between">

            <div className="text-purple-500 text-2xl font-bold">{country}</div>
            <div className="flex justify-end">
              <Button className="text-pink-500 flex" onClick={() => navigate('/')} variant="secondary">
                <svg
                  className="w-3 h-3 mt-1"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.75 19.5L8.25 12l7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Back to map
              </Button>
            </div>
          </CardTitle>
          <div className="grid grid-cols-2 gap-x-6">
            <div className="text-purple-500">Number of Research Groups:</div>
            <div className="text-pink-500">{researchGroupsCount}</div>
            <div className="text-purple-500">Number of PhD students:</div>
            <div className="text-pink-500">{phdStudentCount}</div>
            <div className="text-purple-500">Postdoc researcher:</div>
            <div className="text-pink-500">{postdocResearcherCount}</div>
            <div className="text-purple-500">Number of researcher:</div>
            <div className="text-pink-500">{researcherCount}</div>
          </div>
        </div>
      </Card>
      <div className="flex mb-4">
        <div className="grid grid-cols-4 gap-4 flex-grow">
          {[
            { title: 'Biobank', count: biobankCount, imgSrc: 'biobank.png' },
            {
              title: 'Bioinformatics databases',
              count: bioinformaticsDatabasesCount,
              imgSrc: 'bioinformatics-databases.png',
            },
            { title: 'Clinical trials', count: clinicalTrialsCount, imgSrc: 'clinical-trials.png' },
            { title: 'Databases', count: databasesCount, imgSrc: 'databases.png' },
            { title: 'PC cell lines', count: pcCellLinesCount, imgSrc: 'pc-cell-lines.png' },
            { title: 'PC mouse models', count: pcMouseModelsCount, imgSrc: 'pc-mouse-models.png' },
            {
              title: 'Population-based studies',
              count: populationBasedStudiesCount,
              imgSrc: 'population-based-studies.png',
            },
            { title: 'Registries', count: registriesCount, imgSrc: 'registries.png' },
          ].map(({ title, count, imgSrc }) => (
            <div key={title} className="flex-col items-center justify-center text-left py-4 border-b-2 border-gray-200">
              <div className="flex flex-row items-center">
                <img alt={title} src={`${process.env.PUBLIC_URL}/${imgSrc}`} />
                <div className="text-5xl pl-4 text-pink-500 font-black">{count}</div>
              </div>
              <div className="text-2xl pl-2 leading-none">{title}</div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {filteredResearchGroups.map((researchGroup) => (
          <ResearchGroupCard
            key={researchGroup.id}
            city={researchGroup.city}
            country={researchGroup.country}
            group_name={researchGroup.group_name}
            id={researchGroup.id}
            institution={researchGroup.institution}
            principal_investigator={researchGroup.principal_investigator}
          />
        ))}
      </div>
    </div>
  );
};
