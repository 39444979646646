import React from 'react';
import { Outlet } from 'react-router-dom';
import { Filters } from '../../features/research/components/Filters';

export const Layout = () => (
  <div>
    <nav className="flex w-full md:w-4/5 lg:w-3/4 m-auto">
      <a href="/">
        <img alt="Logo" className="h-12 m-3 mb-2" src={`${process.env.PUBLIC_URL}/logo.png`} />
      </a>
    </nav>
    <div className="w-full bg-white">
      <main className="w-full flex flex-col content-center justify-center" role="main">
        <div className="flex w-full md:w-4/5 lg:w-3/4 m-auto">
          <div className="w-full md:w-4/5 lg:w-1/3  m-3 mb-6">
            <Filters />
          </div>
          <div className="w-full md:w-4/5 lg:w-3/4 m-3 mb-6">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  </div>
);
