import { useEffect } from 'react';
import i18n from 'i18next';
import { translations } from '../mocks/data/translations';

export const useLocale = () => {
  useEffect(() => {
    i18n.addResources('default', 'translation', translations);
    i18n.changeLanguage('default');
  }, []);
};
