import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// axios.defaults.baseURL = 'http://127.0.0.1:8000';
axios.defaults.baseURL = 'https://research-groups.pancreaticcancereurope.eu/';

export const useResearchGroups = (select?: any) => {
  const fetchResearchGroups = () => async () => {
    const response = await axios.get('api/research-groups');
    return response.data.data;
  };

  return useQuery({
    queryKey: ['researchGroups'],
    queryFn: fetchResearchGroups(),
    select,
  });
};

// eslint-disable-next-line max-len
export const useResearchGroup = (id?: string) => {
  const result = useResearchGroups((researchGroups: any) => {
    // eslint-disable-next-line no-console
    console.log('find', researchGroups.find((rg: any) => rg.id === Number(id)));
    return researchGroups.find((rg: any) => rg.id === Number(id));
  });
  // eslint-disable-next-line no-console
  console.log('result', result);
  return result;
};
