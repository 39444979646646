import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { AppProvider } from './providers/app';
import { AddResearchGroup } from './features/admin/components/AddResearchGroup';
import { NoMatch } from './components/Layout/NoMatch';
import { useLocale } from './hooks/useLocale';
import { Country } from './features/research/components/Country';
import { Layout } from './components/Layout/Layout';
import { Map } from './features/research/components/Map';
import { ResearchGroup } from './features/research/components/ResearchGroup';

export const App = () => {
  useLocale();
  return (
    <AppProvider>
      <Routes>
        <Route element={<Layout />} path="/">
          <Route element={<Map />} index />
          <Route element={<AddResearchGroup />} path="add-research-group" />
          <Route element={<Country />} path="/countries/:country" />
          <Route element={<NoMatch />} path="*" />
        </Route>
        <Route element={<ResearchGroup />} path="/research-groups/:researchGroupId" />
      </Routes>
    </AppProvider>
  );
};
