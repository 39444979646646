import React from 'react';
import { Checkbox } from '../Checkbox';

type CheckboxGroupProps = {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
};

export const MyCheckboxGroup = ({ options, onChange, value }: CheckboxGroupProps) => {
  const handleCheckboxChange = (option: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange([...value, option]);
    } else {
      onChange(value.filter(v => v !== option));
    }
  };

  return (
    <div>
      {options.map((option) => (
        <Checkbox
          key={option}
          checked={value.includes(option)}
          id={option}
          label={option}
          onChange={handleCheckboxChange(option)}
        />
      ))}
    </div>
  );
};
