export const researchGroups = [
  {
    id: 1,
    group_name: 'Biomedyczne Badania Rakotwórcze',
    description: 'Jest to grupa badawcza skupiająca się na badaniach biomedycznych związanych z rakiem.',
    institution: 'Uniwersytet Jagielloński',
    city: 'Kraków',
    country: 'Poland',
    research_type: ['Oncology', 'Translational'],
    resources: ['Biobank', 'Bioinformatics databases'],
    principal_investigator: 'Prof. Jan Kowalski',
    researcher_count: '15',
    phd_student_count: '7',
    postdoc_count: '3',
    address: 'ul. Grzegórzecka 20, 30-363 Kraków',
    telephone: '+48 12 345 67 89',
    email: 'biomed@uj.edu.pl',
    website: 'https://www.biomed.uj.edu.pl',
    top_publications: [
      'Kowalski J. et al., (2023). Role of oncogenic pathways in pancreatic cancer. Nature.',
      'Kowalski J. et al., (2022). Translational approaches in oncology. Science.',
      'Kowalski J. et al., (2021). Biobank resources in cancer research. The Lancet.',
      'Kowalski J. et al., (2020). Role of bioinformatics in oncology. Cell.',
      'Kowalski J. et al., (2019). Pancreatic cancer: An overview. BMJ.',
    ],
  },
  {
    id: 2,
    group_name: 'Grupa Badawcza Genomiki',
    description: 'Zajmujemy się badaniem genomiki i jej zastosowań w biologii i medycynie.',
    institution: 'Uniwersytet Warszawski',
    city: 'Warszawa',
    country: 'Poland',
    research_type: ['Basic', 'Pathology'],
    resources: ['Bioinformatics databases', 'Clinical trials'],
    principal_investigator: 'Dr hab. Maria Nowak',
    researcher_count: '20',
    phd_student_count: '10',
    postdoc_count: '5',
    address: 'ul. Miecznikowa 1, 02-096 Warszawa',
    telephone: '+48 22 543 21 00',
    email: 'genomika@uw.edu.pl',
    website: 'https://www.genomika.uw.edu.pl',
    top_publications: [
      'Nowak M. et al., (2023). Genome sequencing in cancer. Nature.',
      'Nowak M. et al., (2022). Advances in genome sequencing. Science.',
      'Nowak M. et al., (2021). Clinical trials in genomics. The Lancet.',
      'Nowak M. et al., (2020). Pathology and genomics. Cell.',
      'Nowak M. et al., (2019). The future of genomics. BMJ.',
    ],
  },
  {
    id: 3,
    group_name: 'Cancer Genomics',
    description: 'Focusing on uncovering genomic alterations in cancer by using genome sequencing and developing '
      + 'new approaches to analyzing such data.',
    institution: 'Cambridge University',
    city: 'Cambridge',
    country: 'United Kingdom',
    research_type: ['Translational', 'Oncology'],
    resources: ['Biobank', 'Bioinformatics databases'],
    principal_investigator: 'Prof. Dr. Susan Thompson',
    researcher_count: 12,
    phd_student_count: 5,
    postdoc_count: 2,
    address: 'Cambridge Biomedical Campus, Hills Rd, Cambridge CB2 0QH, United Kingdom',
    telephone: '+441223336300',
    email: 'contact@cancergenomics.cam.ac.uk',
    website_url: 'https://www.cancergenomics.cam.ac.uk',
    top_publications: [
      'Sample Publication 1',
      'Sample Publication 2',
      'Sample Publication 3',
      'Sample Publication 4',
      'Sample Publication 5',
    ],
    latitude: 52.1756,
    longitude: 0.1356,
  },
  {
    id: 4,
    group_name: 'Bioinformatics and Genomics',
    description: 'Performing high-throughput sequencing data analysis and developing statistical models for '
      + 'genomics applications.',
    institution: 'University of Berlin',
    city: 'Berlin',
    country: 'Germany',
    research_type: ['Basic', 'Bioinformatics'],
    resources: ['Bioinformatics databases'],
    principal_investigator: 'Prof. Dr. Hans Becker',
    researcher_count: 18,
    phd_student_count: 6,
    postdoc_count: 3,
    address: 'Berlin Mitte, 10117 Berlin, Germany',
    telephone: '+493020934800',
    email: 'contact@bioinfogenomics.berlin.de',
    website_url: 'https://www.bioinfogenomics.berlin.de',
    top_publications: [
      'Sample Publication 1',
      'Sample Publication 2',
      'Sample Publication 3',
      'Sample Publication 4',
      'Sample Publication 5',
    ],
    latitude: 52.5200,
    longitude: 13.4050,
  },
  {
    id: 5,
    group_name: 'Clinical Epidemiology',
    description: 'Conducting research on the patterns, causes, and effects of health and disease conditions '
      + 'in defined populations.',
    institution: 'University of Amsterdam',
    city: 'Amsterdam',
    country: 'Netherlands',
    research_type: ['Epidemiology', 'Clinical GI', 'Basic'],
    resources: ['Population-based studies', 'Registries', 'Clinical trials'],
    principal_investigator: 'Prof. Dr. Johan De Vries',
    researcher_count: 22,
    phd_student_count: 8,
    postdoc_count: 4,
    address: 'Spui 21, 1012 WX Amsterdam, Netherlands',
    telephone: '+31205252424',
    email: 'contact@clinicepi.amsterdam.nl',

    website_url: 'https://www.clinicepi.amsterdam.nl',
    top_publications: [
      'Sample Publication 1',
      'Sample Publication 2',
      'Sample Publication 3',
      'Sample Publication 4',
      'Sample Publication 5',
    ],
    latitude: 52.3667,
    longitude: 4.8945,
  },
  {
    id: 6,
    group_name: 'Genetic Medicine',
    description: 'Investigating the ways in which genes contribute to disease in families and populations, '
      + 'and working towards the application of genetic and genomic information to improve health outcomes.',
    institution: 'University of Geneva',
    city: 'Geneva',
    country: 'Switzerland',
    research_type: ['Translational', 'Genetics'],
    resources: ['Biobank', 'Registries', 'PC mouse models'],
    principal_investigator: 'Prof. Dr. Marie Dupont',
    researcher_count: 28,
    phd_student_count: 10,
    postdoc_count: 5,
    address: 'Rue du Rhône 120, 1204 Geneva, Switzerland',
    telephone: '+41227017272',
    email: 'contact@genmed.geneva.ch',
    website_url: 'https://www.genmed.geneva.ch',
    top_publications: [
      'Sample Publication 1',
      'Sample Publication 2',
      'Sample Publication 3',
      'Sample Publication 4',
      'Sample Publication 5',
    ],
    latitude: 46.2044,
    longitude: 6.1432,
  },
  {
    id: 7,
    group_name: 'Neurobiology Research Unit',
    description: 'Studying the underlying neurobiology and complex behaviors associated with the brain or '
      + 'nervous system.',
    institution: 'University of Copenhagen',
    city: 'Copenhagen',
    country: 'Denmark',
    research_type: ['Basic', 'Neurobiology'],
    resources: ['PC mouse models', 'PC cell lines', 'Bioinformatics databases'],
    principal_investigator: 'Prof. Dr. Lars Nielsen',
    researcher_count: 32,
    phd_student_count: 11,
    postdoc_count: 6,
    address: 'Fiolstræde 1, 1171 København, Denmark',
    telephone: '+4535333030',
    email: 'contact@neurobio.copenhagen.dk',
    website_url: 'https://www.neurobio.copenhagen.dk',
    top_publications: [
      'Sample Publication 1',
      'Sample Publication 2',
      'Sample Publication 3',
      'Sample Publication 4',
      'Sample Publication 5',
    ],
    latitude: 55.6761,
    longitude: 12.5683,
  },
  {
    id: 8,
    group_name: 'Lux Med',
    description: 'Grupa LUX MED to lider prywatnej opieki zdrowotnej w Polsce',
    institution: 'Politechnika Poznańska',
    city: 'Poznań',
    country: 'Poland',
    research_type: ['Basic'],
    resources: ['Clinical trials'],
    principal_investigator: 'Jan Kowalski',
    researcher_count: '23',
    phd_student_count: '43',
    postdoc_count: '233',
    address: 'ul. Serbska 11, 61-628 Poznań',
    telephone: '+48 000 000 000',
    email: 'biuro@luxmed.pl',
    website: 'https://www.luxmed.pl',
    top_publications: [
    ],
  },
];
