import React from 'react';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean
  id: string
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
  checked, id, label, onChange,
}: CheckboxProps) => (
  <label className="block my-1" htmlFor={id}>
    <input
      checked={checked}
      className="h-6 w-6 mr-1 rounded border border-solid border-neutral-300 outline-none accent-pink-500 align-middle"
      data-testid={id}
      id={id}
      onChange={onChange}
      type="checkbox"
    />
    <span className="p-2.5">
      {label}
    </span>
  </label>
);
