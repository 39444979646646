import React, { forwardRef } from 'react';
import clsx from 'clsx';

const variants = {
  primary: ' text-slate-500 bg-violet-50 text-violet-700 hover:bg-violet-100',
  secondary: 'text-pink-500 hover:text-purple-500',
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  type?: 'button' | 'submit';
  className?: string;
  variant?: keyof typeof variants;
};

type Ref = HTMLButtonElement;

/* eslint-disable react/button-has-type */
const Button = forwardRef<Ref, ButtonProps>(({
  type = 'button',
  className = '',
  variant = 'primary',
  ...props
}, ref) => (
  <button
    ref={ref}
    className={clsx(
      'block w-full text-sm py-2 px-4 border-0 font-semibold rounded-full',
      variants[variant],
      className,
    )}
    {...props}
    type={type}
  >
    {props.children}
  </button>
));

export { Button, variants };
