import { create } from 'zustand';

interface FilterState {
  country: string;
  setCountry: (country: string) => void;
  researchTypes: string[];
  setResearchTypes: (value: string[]) => void;
  resources: string[];
  setResources: (value: string[]) => void;
  resetFilters: () => void;
}

const useFilterStore = create<FilterState>((set) => ({
  country: '',
  setCountry: (country) => set((state) => ({
    ...state,
    country,
  })),
  researchTypes: [],
  setResearchTypes: (researchTypes) => set((state) => ({
    ...state,
    researchTypes,
  })),
  resources: [],
  setResources: (resources) => set((state) => ({
    ...state,
    resources,
  })),
  resetFilters: () => set({ researchTypes: [], resources: [] }),
}));

export { useFilterStore };
