import React from 'react';
import clsx from 'clsx';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export const Card = ({ children, className }: CardProps) => (
  <div className={clsx(className, 'flex bg-gray-500 mb-2 p-4')}>
    {children}
  </div>
);

export const CardTitle = ({ children, className }: { children: React.ReactNode, className?: string }) => (
  <div className={clsx(className, 'w-full flex text-xl')}>
    {children}
  </div>
);
