import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ResearchGroup } from '../types';

export const useFilterResearchGroups = (
  researchGroups: ResearchGroup[],
  researchTypes: string[],
  resources: string[],
  country?: string,
) => {
  const [filteredResearchGroups, setFilteredResearchGroups] = useState(researchGroups);

  useDeepCompareEffect(() => {
    const filterByCountry = (researchGroup: ResearchGroup) => (
      country ? researchGroup.country === country : true
    );
    const filterByResources = (researchGroup: ResearchGroup) => (
      resources ? resources.every(item => researchGroup.resources.includes(item)) : true
    );
    const filterByResearchType = (researchGroup: ResearchGroup) => (
      researchTypes ? researchTypes.every(item => researchGroup.research_type.includes(item)) : true
    );

    const result = researchGroups
      .filter(filterByCountry)
      .filter(filterByResources)
      .filter(filterByResearchType);

    setFilteredResearchGroups(result);
  }, [researchGroups, country, researchTypes, resources]);

  return { filteredResearchGroups };
};
