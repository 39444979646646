import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ResearchGroup } from '../types';

export const useGroupResearchGroups = (
  researchGroups: ResearchGroup[],
) => {
  const [groupedResearchGroups, setGroupedResearchGroups] = useState({
    researchGroupsCount: 0,
    phdStudentCount: 0,
    postdocResearcherCount: 0,
    researcherCount: 0,
    biobankCount: 0,
    bioinformaticsDatabasesCount: 0,
    clinicalTrialsCount: 0,
    databasesCount: 0,
    pcCellLinesCount: 0,
    pcMouseModelsCount: 0,
    populationBasedStudiesCount: 0,
    registriesCount: 0,

  });

  useDeepCompareEffect(() => {
    if (researchGroups) {
      const researcherCount = researchGroups.map(r => r.researcher_count)
        .reduce((partialSum, a) => partialSum + Number(a), 0);
      const phdStudentCount = researchGroups.map(r => r.phd_student_count)
        .reduce((partialSum, a) => partialSum + Number(a), 0);
      const postdocResearcherCount = researchGroups.map(r => r.postdoc_count)
        .reduce((partialSum, a) => partialSum + Number(a), 0);
      const biobankCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('Biobank') ? 1 : 0), 0);
      const bioinformaticsDatabasesCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('Bioinformatics databases') ? 1 : 0), 0);
      const clinicalTrialsCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('Clinical trials') ? 1 : 0), 0);
      const databasesCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('Databases') ? 1 : 0), 0);
      const pcCellLinesCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('PC cell lines') ? 1 : 0), 0);
      const pcMouseModelsCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('PC mouse models') ? 1 : 0), 0);
      const populationBasedStudiesCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('Population-based studies') ? 1 : 0), 0);
      const registriesCount = researchGroups.reduce((partialSum, r) => partialSum
      + (r.resources.includes('Registries') ? 1 : 0), 0);

      setGroupedResearchGroups({
        researchGroupsCount: researchGroups?.length,
        researcherCount,
        phdStudentCount,
        postdocResearcherCount,
        biobankCount,
        bioinformaticsDatabasesCount,
        clinicalTrialsCount,
        databasesCount,
        pcCellLinesCount,
        pcMouseModelsCount,
        populationBasedStudiesCount,
        registriesCount,
      });
    }
  }, [researchGroups]);

  return { groupedResearchGroups };
};
