import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import {
  GoogleMap, Marker, useLoadScript,
} from '@react-google-maps/api';

import { useResearchGroups } from '../api/useResearchGroups';
import { useFilterResearchGroups } from '../hooks/useFilterResearchGroups';
import { useFilterStore } from '../../../stores/filterStore';
import { ResearchGroup } from '../types';
import { Button } from '../../../components/Elements/Button';
import { groupBy } from '../../../utils/groupBy';

interface GeoJSONType {
  type: string;
  features: Array<{
    type: string;
    properties: { NAME: string };
    geometry: {
      type: string;
      coordinates: number[][][];
    };
  }>;
}

const containerStyle = {
  width: '100%',
  height: '800px',
};

const center = {
  lat: 54.5260,
  lng: 15.2551,
};

interface Locations {
  [key: string]: {
    lat: number;
    lng: number;
  };
}

const locations: Locations = {
  Albania: { lat: 41.1533, lng: 20.1683 },
  Andorra: { lat: 42.5463, lng: 1.6016 },
  Austria: { lat: 47.5162, lng: 14.5501 },
  Belarus: { lat: 53.7098, lng: 27.9534 },
  Belgium: { lat: 50.5039, lng: 4.4699 },
  'Bosnia and Herzegovina': { lat: 43.9159, lng: 17.6791 },
  Bulgaria: { lat: 42.7339, lng: 25.4858 },
  Croatia: { lat: 45.1000, lng: 15.2000 },
  Cyprus: { lat: 35.1264, lng: 33.4299 },
  'Czech Republic': { lat: 49.8175, lng: 15.4730 },
  Denmark: { lat: 56.2639, lng: 9.5018 },
  Estonia: { lat: 58.5953, lng: 25.0136 },
  Finland: { lat: 61.9241, lng: 25.7482 },
  France: { lat: 46.6034, lng: 1.8883 },
  Germany: { lat: 51.1657, lng: 10.4515 },
  Greece: { lat: 39.0742, lng: 21.8243 },
  Hungary: { lat: 47.1625, lng: 19.5033 },
  Iceland: { lat: 64.9631, lng: -19.0208 },
  Ireland: { lat: 53.1424, lng: -7.6921 },
  Italy: { lat: 41.8719, lng: 12.5674 },
  Kazakhstan: { lat: 48.0196, lng: 66.9237 },
  Latvia: { lat: 56.8796, lng: 24.6032 },
  Liechtenstein: { lat: 47.1660, lng: 9.5554 },
  Lithuania: { lat: 55.1694, lng: 23.8813 },
  Luxembourg: { lat: 49.8153, lng: 6.1296 },
  Malta: { lat: 35.9375, lng: 14.3754 },
  Moldova: { lat: 47.4116, lng: 28.3699 },
  Monaco: { lat: 43.7503, lng: 7.4128 },
  Montenegro: { lat: 42.7087, lng: 19.3744 },
  Netherlands: { lat: 52.1326, lng: 5.2913 },
  'North Macedonia': { lat: 41.6086, lng: 21.7453 },
  Norway: { lat: 60.4720, lng: 8.4689 },
  Poland: { lat: 51.9194, lng: 19.1451 },
  Portugal: { lat: 39.3999, lng: -8.2245 },
  Romania: { lat: 45.9432, lng: 24.9668 },
  Russia: { lat: 61.5240, lng: 105.3188 },
  'San Marino': { lat: 43.9424, lng: 12.4578 },
  Serbia: { lat: 44.0165, lng: 21.0059 },
  Slovakia: { lat: 48.6690, lng: 19.6990 },
  Slovenia: { lat: 46.1512, lng: 14.9955 },
  Spain: { lat: 40.4637, lng: -3.7492 },
  Sweden: { lat: 60.1282, lng: 18.6435 },
  Switzerland: { lat: 46.8182, lng: 8.2275 },
  Turkey: { lat: 38.9637, lng: 35.2433 },
  Ukraine: { lat: 48.3794, lng: 31.1656 },
  'United Kingdom': { lat: 55.3781, lng: -3.4360 },
  'Vatican City': { lat: 41.9029, lng: 12.4534 },
};

const mapStyles = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#fefefe',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        weight: '1.5',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#fcf9f9',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#a0a1a2',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#d3c1db',
      },
    ],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

export const Map = () => {
  const navigate = useNavigate();
  const { data } = useResearchGroups();
  const { researchTypes, resources } = useFilterStore((state) => ({
    country: state.country,
    researchTypes: state.researchTypes,
    resources: state.resources,
  }), shallow);
  const { filteredResearchGroups } = useFilterResearchGroups(data || [], researchTypes, resources);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB9d_ay2Ca8zxf-TUIj3_ZgmYc4LqMP9Lc', // <-- Replace with your API key
  });

  const mapRef = React.useRef<google.maps.Map | null>(null);

  const groupedByCountry = groupBy(filteredResearchGroups, 'country');

  // State to hold the fetched GeoJSON data
  const [fetchedGeoJSON, setFetchedGeoJSON] = React.useState<GeoJSONType | null>(null);

  // useEffect to fetch the GeoJSON data only once
  React.useEffect(() => {
    async function fetchGeoJSON() {
      const response = await fetch('/media/europe.geojson');
      const data = await response.json();
      setFetchedGeoJSON(data);
    }

    fetchGeoJSON();
  }, []); // empty dependency array means this runs once when the component mounts

  // useEffect to handle the highlighting based on `groupedByCountry`
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Countries in groupedByCountry:', Object.keys(groupedByCountry));

    if (mapRef.current && fetchedGeoJSON) {
      const mapInstance = mapRef.current;

      // Clear existing features
      mapInstance.data.forEach((feature) => {
        mapInstance.data.remove(feature);
      });

      // Filter GeoJSON Features Based on Displayed Markers
      const highlightedCountries = fetchedGeoJSON.features.filter(
        (feature: { properties: { NAME: string } }) => groupedByCountry[feature.properties.NAME],
      );

      mapInstance.data.addGeoJson({
        type: 'FeatureCollection',
        features: highlightedCountries,
      });

      mapInstance.data.setStyle({
        fillColor: '#C70A86',
        fillOpacity: 0.6,
        strokeWeight: 1,
        strokeColor: '#fff',
        zIndex: 1000,
      });
    }
  }, [groupedByCountry, fetchedGeoJSON]);

  if (!data || !isLoaded) {
    return null;
  }

  return (
    <div>
      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        onLoad={(map) => {
          mapRef.current = map;
        }}
        options={{
          styles: mapStyles,
          disableDefaultUI: true, // disable all default UI
          zoomControl: false, // disable zoom control
          mapTypeControl: false, // disable map type control
          scaleControl: false, // disable scale control
          streetViewControl: false, // disable street view control
          rotateControl: false, // disable rotate control
          fullscreenControl: false, // disable fullscreen control
        }}
        zoom={4}
      >
        {window.google && window.google.maps && Object.entries(groupedByCountry).map(([country, value]) => {
          const location = locations[country];
          if (location) {
            const pathD = 'm19.996281,3.172512c-9.642361,0 -17.458275,7.818017 -17.458275,17.46199c0,9.647755 '
              + '17.458275,42.192986 17.458275,42.192986s17.465714,-32.545231 17.465714,-42.192986'
              + 'c0,-9.643973 -7.815916,-17.46199 -17.465714,-17.46199z';

            const svgIcon = `
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="64" viewBox="0 0 40 64">
                  <!-- Main shape with border -->
                  <path d="${pathD}" fill="#C70A86" stroke="white" stroke-width="2"/>
                  <text 
                      x="50%" 
                      y="35%" 
                      dominant-baseline="middle" 
                      text-anchor="middle" 
                      fill="white" 
                      font-size="28px" 
                      font-family="Arial">
                      ${(value as ResearchGroup[]).length}
                  </text>
              </svg>`;

            return (
              <Marker
                key={country}
                icon={{
                  url: `data:image/svg+xml,${encodeURIComponent(svgIcon)}`,
                  scaledSize: new window.google.maps.Size(40, 60),
                }}
                onClick={() => navigate(`/countries/${country}`)}
                position={location}
                title={country}
              />
            );
          }
          return null;
        })}
      </GoogleMap>
      <div className="flex p-6">
        {
        Object.entries(groupedByCountry).map(([key, value]) => (
          <div key={key} className="flex mb-4 mr-2">
            <Link
              to={`/countries/${key}`}
            >
              <Button>{`${key} ${(value as ResearchGroup[]).length}`}</Button>
            </Link>
          </div>
        ))
      }
      </div>
    </div>
  );
};
