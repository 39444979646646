import React, { useEffect } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { shallow } from 'zustand/shallow';
import { useNavigate, useParams } from 'react-router-dom';
import { MyCheckboxGroup } from '../../../components/Elements/CheckboxGroup/CheckboxGroup';
import { Card } from '../../../components/Elements/Card';
import { Select } from '../../../components/Elements/Select';
import { useFilterStore } from '../../../stores/filterStore';

const countries = [
  { label: 'Select a Country', value: '' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'North Macedonia', value: 'North Macedonia' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Kingdom', value: 'United Kingdom' },
];

const Filters = () => {
  const {
    country, setCountry, researchTypes, setResearchTypes, resources, setResources, resetFilters,
  } = useFilterStore((state) => ({
    country: state.country,
    setCountry: state.setCountry,
    researchTypes: state.researchTypes,
    setResearchTypes: state.setResearchTypes,
    resources: state.resources,
    setResources: state.setResources,
    resetFilters: state.resetFilters,
  }), shallow);
  const navigate = useNavigate();

  const isFiltersApplied = researchTypes || resources;
  const { country: currentCountry } = useParams();
  useEffect(() => {
    if (currentCountry) {
      setCountry(currentCountry);
    }
  }, [currentCountry, setCountry]);

  return (
    <>
      <Card>
        <Select
          onChange={(country: string) => {
            setCountry(country);
            if (country) {
              navigate(`/countries/${country}`);
            } else {
              navigate('/');
            }
          }}
          options={countries}
          value={country}
        />
      </Card>
      <Card>
        <PhotoIcon className="h-8 w-8 text-purple-500 m-4" />
        <div className="flex-col">
          <div className="mt-4 mb-2 text-lg text-purple-500 font-bold">Type of Research</div>
          <MyCheckboxGroup
            onChange={(researchTypes) => setResearchTypes(researchTypes)}
            options={[
              'Basic',
              'Clinical GI',
              'Epidemiology',
              'Oncology',
              'Other',
              'Pathology',
              'Radiology',
              'Surgery',
              'Translational',
            ]}
            value={researchTypes}
          />
        </div>
      </Card>
      <Card>
        <PhotoIcon className="h-8 w-8 text-purple-500 m-4" />
        <div className="flex-col">
          <div className="mt-4 mb-2 text-lg text-purple-500 font-bold">Resources</div>
          <MyCheckboxGroup
            onChange={(resource) => setResources(resource)}
            options={[
              'Biobank',
              'Bioinformatics databases',
              'Clinical trials',
              'Databases',
              'PC cell lines',
              'PC mouse models',
              'Population-based studies',
              'Registries',
            ]}
            value={resources}
          />
        </div>
      </Card>
      {isFiltersApplied && (
        <button
          className="py-2 px-4 font-semibold rounded-lg focus:outline-none"
          onClick={() => {
            resetFilters();
            setResearchTypes([]);
            setResources([]);
          }}
          type="button"
        >
          Reset Filters
        </button>
      )}

    </>
  );
};

export { Filters };
