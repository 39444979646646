import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Option } from '../../../features/research/types';

export function Select({ options, onChange, value }: { options: Option[]; onChange: any, value: any }) {
  return (
    <Listbox onChange={onChange} value={value}>
      <div className="relative w-full">
        <Listbox.Button className="relative w-full cursor-default appearance-none block w-full px-3 py-2">
          <span className="block truncate text-left">{options.find(option => option.value === value)?.label}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-black"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* eslint-disable react/no-array-index-key */}
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base
          shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.label}
                className={({ active }) => `relative cursor-default select-none py-2 pl-4 pr-4 ${
                  active ? 'bg-blue-600 text-white' : 'text-gray-900'
                }`}
                value={option.value}
              >
                {({ selected }) => (
                  <span
                    className={`block truncate ${
                      selected ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {option.label}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
