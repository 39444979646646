import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardTitle } from '../../../components/Elements/Card';
import { Button } from '../../../components/Elements/Button';

interface ResearchGroupCardProps {
  id: string;
  city: string;
  country: string;
  group_name: string;
  institution: string;
  principal_investigator: string;
}

export const ResearchGroupCard = ({
  id, city, country, group_name, institution, principal_investigator,
}: ResearchGroupCardProps) => (
  <Card className="flex-col">
    <CardTitle className="justify-between">
      <div className="text-lg text-purple-500 font-bold">{group_name}</div>
      <div className="flex justify-end">
        <Link
          to={`/research-groups/${id}`}
        >
          <Button className="text-pink-500 flex" variant="secondary">
            View more
            <svg
              className="w-3 h-3 mt-1.5"
              fill="none"
              stroke="currentColor"
              strokeWidth={1}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.25 4.5l7.5 7.5-7.5 7.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
        </Link>
      </div>
    </CardTitle>
    <div className="grid grid-cols-2 gap-x-6">
      {/* <div className="text-purple-500">Name of the Team/Group:</div>
      <div className="text-pink-500">{group_name}</div> */}
      <div className="text-purple-500">Principal Investigator:</div>
      <div className="text-pink-500">{principal_investigator}</div>
      <div className="text-purple-500">Institution:</div>
      <div className="text-pink-500">{institution}</div>
      <div className="text-purple-500">City:</div>
      <div className="text-pink-500">{city}</div>
      <div className="text-purple-500">Country:</div>
      <div className="text-pink-500">{country}</div>
    </div>
  </Card>
);
