import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Card, CardTitle } from '../../../components/Elements/Card';
import { Button } from '../../../components/Elements/Button';
import { useResearchGroup } from '../api/useResearchGroups';

export const ResearchGroup = () => {
  const { researchGroupId } = useParams();
  const { data } = useResearchGroup(researchGroupId);
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    group_name, institution, city, country, principal_investigator, phd_student_count, postdoc_count, researcher_count,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    resources, research_type, address, telephone, email, website, description, top_publications,
  } = data;

  const publicationsArray = JSON.parse(top_publications);
  return (
    <div>
      <nav className="flex w-full md:w-4/5 lg:w-3/4 m-auto">
        <a href="/">
          <img alt="Logo" className="h-12 m-3 mb-2" src={`${process.env.PUBLIC_URL}/logo.png`} />
        </a>
      </nav>
      <div className="w-full bg-white">
        <main className="w-full flex flex-col content-center justify-center" role="main">
          <div className="flex w-full md:w-4/5 lg:w-3/4 m-auto w-full">
            <Card className="flex-col w-full m-3 mb-3">
              <CardTitle className="justify-between">
                <div className="text-2xl text-purple-500 font-bold">{group_name}</div>
                <div className="w-1/5">
                  <Button
                    className="text-pink-500 flex
                    justify-end"
                    onClick={() => navigate(-1)}
                    variant="secondary"
                  >
                    <svg
                      className="w-3 h-3 mt-1"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={1}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15.75 19.5L8.25 12l7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Back to overview
                  </Button>
                </div>
              </CardTitle>
              <div className="flex p-4">
                <div className="flex gap-x-4">
                  <div className="grid grid-cols-2 gap-x-6 ">
                    <div className="text-purple-500">Institution:</div>
                    <div className="text-pink-500">{institution}</div>
                    <div className="text-purple-500">City:</div>
                    <div className="text-pink-500">{city}</div>
                    <div className="text-purple-500">Country:</div>
                    <div className="text-pink-500">{country}</div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-6">
                    <div className="text-purple-500">Principal Investigator:</div>
                    <div className="text-pink-500">{principal_investigator}</div>
                    <div className="text-purple-500">Number of PhD students:</div>
                    <div className="text-pink-500">{phd_student_count}</div>
                    <div className="text-purple-500">Postdoc researcher:</div>
                    <div className="text-pink-500">{postdoc_count}</div>
                    <div className="text-purple-500">Number of researcher:</div>
                    <div className="text-pink-500">{researcher_count}</div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="flex w-full md:w-4/5 lg:w-3/4 m-auto">
            <div className="w-full md:w-4/5 lg:w-1/3  m-3 mb-6">
              <Card>
                <PhotoIcon className="h-8 w-8 text-purple-500 m-4" />
                <div className="flex-col">
                  <div className="mt-4 mb-2 text-lg text-purple-500 font-bold">Type of Research</div>
                  <ul>
                    {research_type.map((researchType: string) => (
                      <li key={researchType} className="text-pink-500">
                        {researchType}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card>
              <Card>
                <PhotoIcon className="h-8 w-8 text-purple-500 m-4" />
                <div className="flex-col">
                  <div className="mt-4 mb-2 text-lg text-purple-500 font-bold">Resources available</div>
                  <ul>
                    {resources.map((resource: string) => (
                      <li key={resource} className="text-pink-500">
                        {resource}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card>
              <Card>
                <PhotoIcon className="h-8 w-8 text-purple-500 m-4" />
                <div className="flex-col">
                  <div className="mt-4 mb-2 text-lg text-purple-500 font-bold">Contacts</div>
                  <p>Address</p>
                  <p className="mb-2 text-pink-500">{address}</p>
                  <p>Telephone:</p>
                  <p className="mb-2 text-pink-500">{telephone}</p>
                  <p className="mt-2">Email:</p>
                  <a className="text-pink-500" href={`mailto:${email}`}>{email}</a>
                  <p className="mt-2">Website:</p>
                  <a className="text-pink-500" href={website}>{website}</a>
                </div>
              </Card>
            </div>
            <div className="w-full md:w-4/5 lg:w-3/4 m-3 mb-6">
              <div className="mt-2 mb-2 text-lg text-purple-500 font-bold">Group description</div>
              <p>{description}</p>
              <div className="mt-2 mb-2 text-lg text-purple-500 font-bold">Top publications</div>
              <ul>
                {publicationsArray.map((publication:string) => (
                  <li key={publication} className="text-purple-500">{publication}</li>
                ))}
              </ul>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
